
@import "~react-image-gallery/styles/css/image-gallery.css";


*{
  margin: 0;
  padding: 0;
  font-family: system-ui;
  padding-left: 0;
}


/* react image gallery */

.image-gallery-content .image-gallery-slide .image-gallery-image{max-height: calc(70vh - 80px);border: 1px solid #ede6e6cc;}
.image-gallery-left-nav .image-gallery-svg{display: none;}
.image-gallery-right-nav .image-gallery-svg{display: none;}
.image-gallery-thumbnail .image-gallery-thumbnail-image{height: 75px;width: 100%;}
.image-gallery-thumbnail{width: 85px;}
.image-gallery-thumbnails .image-gallery-thumbnails-container{white-space: pre-wrap;}
.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus{border: 2px solid #c3c9a8;}

/* end react image gallery */

/* tab panel style  */
.css-1aquho2-MuiTabs-indicator{
  background-color: #727E1E !important;
}
.css-ttwr4n{  background-color: #727E1E !important;}

/********************************* header **********************************/
/* top header */



.header-top{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 115px;
  flex-wrap: wrap;
  background-color:#424B1D ;
  color: white;
  font-weight: 200;
}

.header-top .social-icons a{
 margin-right: 6px;
 color: white;
 font-size: 23px;
}
.header-top .switch-lang{
  cursor: pointer;
}
.header-navbar{
  position: fixed;
  width: 100%;
  z-index: 40;
}
/*  switch langue */
.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
/*  end switch langue  */


/* middle header */
.hamburger {
  display: none;
}
.header-menu{padding:0 168px}
.header-middle{background-color: white;display: flex;justify-content: center;align-items: center;padding: 10px;width: 100%;}
.header-middle ul{list-style: none;display: flex;margin: 0;}
.header-middle ul li{margin-right: 30px;}
.header-middle ul li a {text-decoration: none;color: black;}
.header-middle ul li a:hover { color: #6B7B30; text-decoration: underline black; }
.logo-icons{width: 100%;background-color: #424B1D;display: flex;justify-content: center;padding: 0px;}
.logo-icons img{width: 170px; height: 125px;margin-top: -30px;padding: 2px; }
.logo-icons img:hover{cursor: pointer;}

.shop-area{display: flex; align-items: center;justify-content: space-between;}
.shop-header-icon{margin-right: 26px;display: flex;}
.shop-icon{font-size: 25px;}
.shop-header-icon .shop-counter{
   background-color: #DEE354; 
   width: 24px; 
   height: 24px; 
   position: relative;
   margin: -6px;
   border-radius:100px; 
   -webkit-border-radius:100px; 
   -moz-border-radius:100px; 
   -ms-border-radius:100px; 
   -o-border-radius:100px; 
   
  }
.shop-counter span{margin:2px 7px;font-size: 14px;position: absolute;font-weight: 700;}
.price-header-icon{font-size: 20px;}
/* middle header */

/* bottom header */

.header-bottom{
  /* background: url(images/header/slider-bg.png) no-repeat center 0%/100% 100%; */
  /* height: 40vw; */
}
.pre-header-bottom{
  padding: 5.5rem ;
}

.header-product-img img{
  height: 500px;
  width: 500px;
  margin: 65px 50px;
}


.header-product-infos {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   margin: auto;
   width: 30%;
   position: relative;
   left: 5%;
   top: 30%;
  
}

.header-product-infos .header-title-product{
  color:#a6ad82;
  font-weight: 200;
  font-size: 1.5vw;
  padding: 1% 0;
}
.header-product-infos .header-details-product{
  color:white;
  font-weight: 700;
  font-size: 1.5vw;
  padding: 2% 0;

}
.header-product-infos .header-price-product{
  color:#DEE355;
  font-weight: 900;
  font-size: 2vw;
  padding: 2% 0;

}

.header-product-infos button{
  cursor: pointer;
  color:#485032;
  padding: 2% 3%;
  font-weight: 600;
  font-size: 1.1vw;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
/* bottom header */
/********************************** end header *********************************/

/********************************** header page *********************************/
.Header-page{
  background: url(./images/header-bg1.png) center 100%/100% 100% no-repeat;
  height: 15em;
}
/* .Header-page::after{
  content: "";
  display: block;
  height: 515px;
  width: 8%;
  right: 0%;
  position: absolute;
  background: url("./images/shape-3.png") center 100%/100% no-repeat;
} */
.header-page-infos{
  color: white;
  padding: 70px 0;
  margin: auto;
  display: table;
  text-align: center;
}
.header-page-infos h2{
  margin: auto;
  font-size: 25px;
}
.header-page-infos a{
  text-decoration: none;
  color: #DEE354;
}
.header-page-infos h3{
  font-weight: 400;
  color: #DEE355;
  font-size: 17px;
}
/********************************** end header age *********************************/

/**********************************  title section *********************************/
.titile-section{
  margin: 30px;
  text-align: center;
  padding: 10px;
  color:#3E471B;
  font-weight: 700;
  font-size: 22px;
  letter-spacing: 1px;
  
}
.titile-section-icon{
  text-align: center;
  color: white;
  font-weight: 700;
  font-size: 19px;
  letter-spacing: 1px;
  margin: 30px;
  transform: translate(0, 90px);
  -webkit-transform: translate(0, 100px);
  -moz-transform: translate(0, 90px);
  -ms-transform: translate(0, 90px);
  -o-transform: translate(0, 90px);
}
/* .titile-section-icon::before{
  content: "";
  display: block;
  height: 87px;
  width: 65px;
  right: 1%;
  position: absolute;
  background: url("./images/bgshape-2.png") center 100%/100% no-repeat;
} */
/********************************** end title section ******************************/

/********************************** product section *********************************/
/* product section */

.product-container{display: flex;justify-content: center;flex-wrap: wrap;padding-bottom: 40px;}

/* .product-after::after{
  content: "";
  display: block;
  height: 515px;
  width: 8%;
  right: 0%;
  position: absolute;
  background: url("./images/bgshape-2.png") center 100%/100% no-repeat;
} */

.product-container::before{
  content: "";
  display: block;
  height: 290px;
  width: 3%;
  left: 2%;
  position: absolute;
  background: url("images/bgshape-1.png") center 100%/100% no-repeat;
}

.product-section{
  display: inline-block;
  width: 350px;
  margin-left: 15px;
  cursor: pointer;
      
}
.product-section:hover{
  transform: scale(1.02);
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -ms-transform: scale(1.02);
  -o-transform: scale(1.02);
  box-shadow: 0px 1px 39px 9px rgb(107 124 49 / 2%)
}
.product-details h6 {
  color: gray;
  font-weight: 200;
  font-size: 20px;
  text-align: center;
  padding: 4px 0;

}
.product-details h5 {
  color: #3E471B;
  font-weight: 800;
  font-size: 20px;
  text-align: center;
  padding: 1px 0;

}
.img-product{
  border: 1px solid #3e471b1e;
  background-color:#FCFCFC ;
  padding: 40px;
}
.img-product img {
  width: 100%;
  height: 100%;
}
.img-product svg{
 background-color: #DEE355;
 display: block;
 position:absolute;
padding: 9px;
margin: 6px 9px ;
float: right;
 border-radius: 100% ;
 -webkit-border-radius: 100% ;
 -moz-border-radius: 100% ;
 -ms-border-radius: 100% ;
 -o-border-radius: 100% ;
}

/* product section*/
/*********************************** end product section ******************************/


/*********************************** Client say section ******************************/
/* client say section*/
.pre-clientSay-conatiner{
  background: url("images/testimonial-bg.png") center 100%/100% 100% no-repeat;
  min-height: auto !important;
}

.pre-clientSay-icon-right{
  position:absolute;
  height:23em;
  right:0;
  opacity:0.1;
  margin-top:1em;
}
.pre-clientSay-icon-left{
  position:absolute;
  height:6em;
  left:0;
}

/* .pre-clientSay-conatiner::before{
  content: "";
  display: block;
  height: 87px;
  width: 65px;
  right: 1%;
  position: absolute;
  background: url("./images/bgshape-2.png") center 100%/100% no-repeat;
} */
.clientSay-conatiner{
  padding-top: 75px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  height: 470px;
  align-content: flex-start;
}
/* .clientSay-conatiner p{
  font-size: 14px;
} */
.clientSay-card{
  color: white;
  display: block;
  width: 300px;
  margin-left: 45px;
}
.clientSay-card p{
  margin: 0;
}
.clientSay-card::before{
  content: ' ';
  height: 25px;
  display: inline-block;
  width: 30px;
  font-size: 50px;
  position: relative;
  top: 28px;
  right: 36px;
  background-image: url(./images/qoute.svg);
}
.clientSay-card h5{
  color: #DEE355;
  padding: 0px 0;
  font-size: 15px;
}
.clientSay-card h5::before{
  content: '--  ';
}
/* client say section*/
/*********************************** end Client say section ****************************/



/***********************************  Blog section ****************************/
  .blog-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 0;
  }
  .blog-container::content{
    content: "";
    display: block;
    height: 100px;
    width: 65px;
    right: 2%;
    position: absolute;
    background: url("./images/bgshape-6.png") center 100%/100% no-repeat;
  }
  .blog-container::before{
    content: "";
    display: inline;
    height: 388px;
    width: 3%;
    right: 0%;
    position: absolute;
    background: url("./images/bgshape-7.png") center 100%/100% no-repeat;
  }
  .titile-section-blog::after{
    content: "";
    display: block;
    height: 191px;
    width: 3%;
    right: 2%;
    position: absolute;
    background: url("./images/bgshape-6.png") center 100%/100% no-repeat;
  }
  .blog-container::after{
    content: "";
    display: block;
    height: 300px;
    width: 4%;
    left: 0%;
    position: absolute;
    background: url("./images/bgshape-5.png") center 100%/100% no-repeat;
  }

  .Post-card{
    width: 300px;
    box-shadow: 0px 1px 24px 5px rgba(106, 124, 49, 0.082);
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    margin: 25px;
    cursor: pointer;
    transition: transform .2s;

}
.Post-card:hover{
  transform: scale(1.02);
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -ms-transform: scale(1.02);
  -o-transform: scale(1.02);
}
.Post-card .post-details{
  padding: 15px;
}
.post-details h5{
  color: #3E471B;
  padding: 4px 0;
  font-weight: 100;
  font-size: 14px;
}
.post-details h4{
  color: #3E471B;
  padding: 4px 0;
  font-size: 17px;
}
.post-details p{
  color:rgb(68, 64, 64);
  font-size: 15px;
  font-weight: 100;
}
.Post-card a{
  text-decoration: none;
}
  .Post-card img{
    width: 100%;
    height: 200px;
  }


/****** blog page */

.blog-page-latest{
 
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(./images/blog/squrare-bg.png) center 100%/17% 100% no-repeat;
  margin: 15px;
  flex-wrap: wrap;
  padding: 30px;

}
.blog-page-latest::after{
  content: "";
  display: block;
  height: 480px;
  width: 5%;
  left: 2%;
  position: absolute;
  background: url(./images/bgshape-1.png) center 100%/100% no-repeat;
}

.blog-latest-infos{
  display: flex;
  flex-direction: column;
  background-color: #6B7B30;
  color: white;
  width: 450px;
  padding: 7px;
}
.blog-latest-infos h6{
  margin:35px 40px 0px;
  color: #DEE354;
  font-weight: 300;
}
.blog-latest-infos h3{
 
  margin: 10px 40px;
  font-size: 22px;
}
.blog-latest-infos p{
  
  margin: 10px 40px;
  font-weight: 300;
  font-size: 14px;
}
.blog-latest-infos a{
  margin: 10px 40px;
    text-decoration: none;
    background-color: white;
    padding: 10px 20px;
    font-weight: 500;
    font-size: 13px;
    width: 120px;
    color: #424B1E;
    border-radius: 5px;
}

.blog-latest-img{
  display: flex;
  align-items: center;
  width: 450px;
}
.blog-latest-img img{
 width: 100%;
 height: 363px;
}
/****** end blog page  */


/*********************************** end Blog section ****************************/



/*********************************** blog details section ****************************/

.blog-details-infos{
  color: white;
  padding-top: 25px;
  padding-left: 16%;
  padding-right: 16%;
}
.blog-details-infos h6{
  font-weight: 300;
  font-size: 15px;
}
.blog-details-infos h2{
  font-size: 170%;
  text-transform: uppercase;
}
.blog-details-content::before{
  content: "";
  display: block;
  height: 40%;
  width: 5%;
  left: 1%;
  position: absolute;
  background: url("images/bgshape-1.png") center 100%/100% no-repeat;
}
.blog-details-img::after{
  content: "";
  display: block;
  height: 48%;
  width: 5%;
  right: -19%;
  position: absolute;
  background: url("images/bgshape-6.png") center 100%/100% no-repeat;
}
.blog-details-text::before{
  content: "";
  display: block;
  height: 80%;
  width: 5%;
  left: 0%;
  position: absolute;
  background: url("images/bgshape-5.png") center 100%/100% no-repeat;
}
.blog-details-after::after{
  content: "";
  display: block;
  height: 50%;
  width: 3%;
  right: 0%;
  position: absolute;
  background: url("images/bgshape-7.png") center 100%/100% no-repeat;
}
.blog-details-img{
  width: 70%;
  margin: auto;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  transform: translate(0, -120px);
  -webkit-transform: translate(0, -120px);
  -moz-transform: translate(0, -120px);
  -ms-transform: translate(0, -120px);
  -o-transform: translate(0, -120px);
}
.blog-details-img img {
  width: 100%;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  position: relative;
  height: 35vw;
}

.blog-details-text{
  padding-left: 15%;
  padding-right: 15%;
  margin-top: -50px;
}
/*********************************** end blog details section ****************************/


/*********************************** instagram section ****************************/
/* instagram section*/

.instagram-container{
  display: flex;
 
  justify-content: center;
  flex-wrap: wrap;

}
.instagram-container img{

  width: 300px;
}
/* instagram section*/
/********************************** end instagram section *************************/



/*********************************** about section *******************************/
.about-section{
  margin: 90px 0;
}

.about-infos{
  display: flex;
  justify-content:center;
  flex-wrap: wrap;
}

.about-infos::after{
  content: "";
  display: block;
  height: 300px;
  width: 60px;
  left: 0%;
  position: absolute;
  background: url("images/bgshape-5.png") center 100%/100% no-repeat;
}
.about-infos::before{
  content: "";
  display: block;
  height: 350px;
  width: 70px;
  right: 0%;
  position: absolute;
  background: url("images/bgshape-7.png") center 100%/100% no-repeat;
}
.about-form{
  border: 1px solid red;
}

.about-card {
  width: 450px;
  margin: 30px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  box-shadow: 0px 1px 39px 9px rgba(107,124,49,0.11);
}
.about-card:hover{
  cursor: pointer;
  transform: scale(1.02);
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -ms-transform: scale(1.02);
  -o-transform: scale(1.02);
}
.about-card-details{
  padding: 20px;
}
.about-card-details h3{
  padding-bottom: 10px;
}
.about-card-details p{
  font-weight: 200;
}
.about-card img{
   width: 100%;
}
.about-card h3{
  font-weight: 400;
}

/******/
.about-det {
  padding:20px 45px;
  background-color: transparent;
  margin: 5px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  
}
.about-det h2{
  color: #7A944E;
  margin-bottom: 20px;
  text-align: center;
  font-size: 25px;
}
.about-det h4{
  font-weight: 400;
  text-align: justify;
  font-size: 17px;
}



.about-details-infos{
  padding: 19px;
  text-align: justify;
  margin: 30px;
  /* box-shadow: 0px 1px 39px 9px rgb(107 124 49 / 3%); */
  border-radius: 13px;
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  -ms-border-radius: 13px;
  -o-border-radius: 13px;
}

.about-details-icons{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  text-align: center;
}
.about-details-icons h3{
  font-size: 14px;
  font-weight: 400;
}

.about-title-section{
  color:#7A944E ;
  padding-bottom: 20px;
  text-align: center;
  font-size: 22px;
}
/*********************************** end about section ****************************/



/********************************** product page section   ****************************/

.product-page{
  display: flex;
  justify-content: center;
  margin: 50px 0;
  flex-wrap: wrap;
}
.product-page::before{
  content: "";
  display: block;
  height: 41em;
  width: 5%;
  left: 1%;
  position: absolute;
  background: url(./images/bgshape-1.png) center 100%/100% no-repeat;
}
.product-page::after{
  content: "";
  display: block;
  height: 290px;
  width: 6%;
  right: 1%;
  position: absolute;
  background: url(./images/bgshape-8.png) center 100%/100% no-repeat;
}

.product-page-img{
  width: 500px;
  background-color: #FCFCFC;
  border: 1px solid gainsboro;
  margin: 24px;
  padding: 23px;
 
}
.product-page-img img{
  width: 100%;
 }

.product-page-infos{
  width: 500px;
  margin: 20px;
  padding: 50px 0;
}
.product-page-infos h3{
  font-weight: 400;
  font-size: 28px;
}
.product-page-infos h6{
  color: #dee165;
  font-size: 15px;
  padding:3px 0;
  font-size: 11px;
}
.product-page-infos h2 {
  color: #6B7C2F;
  font-weight: 800;
  font-size: 32px;
  padding:5px 0;
}
.product-page-infos p {
  color: grey;
  text-align: justify;
  font-size: 17px;
  padding:30px 0;
  
}

.product-page-infos a{
  padding: 17px 90px;
  background-color: #DEE355;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  color: black;
  border: none;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
 
}
.product-page-infos button:hover{
  background-color: #eff36c;
  transition: 0.9s;
  -webkit-transition: 0.9s;
  -moz-transition: 0.9s;
  -ms-transition: 0.9s;
  -o-transition: 0.9s;
  cursor: pointer;
}

.product-page-title{
  padding: 25px;
  text-align: center;
}
.product-page-title h2{
 font-weight: 400;
}
.product-page-title h2::after{
  content: '   ————';
  color: #9eb34d;
  font-weight: 200;
 }
 .product-page-title h2::before{
  content: '———   ';
  color: #9eb34d;
  font-weight: 200;
 }

 .product-page-desc{
   margin:4% 15%;
 }
 .product-page-desc::before{
  content: "";
  display: block;
  height: 290px;
  width: 5%;
  right: 3%;
  position: absolute;
  background: url(./images/bgshape-1.png) center 100%/100% no-repeat;
 }
 .product-page-desc h2{
  color: rgb(80, 79, 79);
  margin-bottom: 15px;
  border-bottom: 3px solid #9eb34d;
  display: inline-block;
  padding: 8px 0;
}
.product-page-desc p{
  color: rgb(71, 71, 71);
  font-size: 18px;
  text-align: justify; 
  margin: 20px 0;
}


.product-page-img-section{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.product-img-select{
display: flex;
justify-content: flex-start;
flex-wrap: wrap;

}
.product-img-div{
  margin: 6px;
}
.product-img-select img{
  width: 81px;
  height: 80px;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  box-shadow: 0px 0px 2px 1px #83953a4b;

  }

.product-page-img-section img{
  height: 390px;
  margin: 18px;
  
}
.product-page-img-section img:hover{
  box-shadow: 0px 1px 39px 9px rgba(66, 94, 29, 0.1); 
  cursor: pointer;
  transform: scale(1.02);
}
.product-page-img-comment{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.product-page-img-comment img{
  height: 390px;
  margin: 18px;
}
.product-page-img-comment img:hover{
  box-shadow: 0px 1px 39px 9px rgba(66, 94, 29, 0.1); 
  cursor: pointer;
  transform: scale(1.02);
}
/* .product-page-img-section::after{
  content: "";
  display: block;
  height: 290px;
  width: 85px;
  right: 0%;
  position: absolute;
  background: url(./images/bgshape-2.png) center 100%/100% no-repeat;
} */

/********************************** end product page section ***************************/

/**********************************  contatc page section ***************************/


.contact-section{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 90px;
  align-items: center
}
.contact-col{
 margin: 20px;
}
.contact-infos h1{
   color: #6B7B30;
   font-size: 2.3rem;
   padding-bottom: 5px;
}
.contact-infos h3{
  color: rgb(31, 30, 30);
  padding: 25px 0;
  font-weight: 400;
}
.contact-infos li{
  list-style: none;
  padding-bottom: 14px;
}
.contact-infos li a{
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  
}
.contact-infos li svg{
  color: white;
  font-size: 33px;
  margin-right: 10px;
  background-color: #6B7B30;
  padding: 7px;
  border-radius: 17px;

}
.contact-form{
  padding: 30px;
  box-shadow: 0px 8px 16px 0px rgba(41, 46, 29, 0.13);
  border-radius:12px;
  -webkit-border-radius:12px;
  -moz-border-radius:12px;
  -ms-border-radius:12px;
  -o-border-radius:12px;
}
.contact-form form {
  display: flex;
  flex-direction: column;
}
.contact-form h2{
  color: #6B7B30;
  padding-bottom: 5px;
}
.contact-form h6{
  color: rgb(24, 24, 24);
  padding-bottom: 5px;
  font-weight: 400;
  font-size: 11px;
}
.contact-form input{
  padding-bottom: 10px;
}
.contact-form input[type=text]{
  margin: 8px;
  border: none;
  border-bottom: 1px solid #aaad9a;
  padding: 10px 0;
  width: 100%;
}
.contact-form .row{
 display: flex;
 justify-content: space-between;
 flex-wrap: nowrap;
}
.contact-form label{
color: #5c5f4d;
font-weight: 400;
font-size: 15px;
margin-bottom: 5px;
}
.contact-form button{
  cursor: pointer;
  background-color: #6B7B30;
  color: white;
  padding: 5px 6px;
  font-weight: 600;
  border: none;
  margin: 10px auto;
  width: 150px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.contact-form button:hover{
  background-color: #97ad44;
}
/**********************************  contact page section ***************************/









/*********************************** prefooter section ****************************/
/* prefooter section*/

.pre-footer{
  display: flex;
  padding: 40px;
  justify-content: center;
  font-weight: 700;
  color: #485032;
}

/* .pre-footer::before{
  content: "";
  display: block;
  height: 96px;
  width: 5%;
  left: 5%;
  position: absolute;
  background: url("images/bgshape-8.png") center 100%/100% no-repeat;
} */
/* .pre-footer::after{
  content: "";
  display: block;
  height: 85px;
  width: 4%;
  right: 0;
  position: absolute;
  background: url("images/bgshape-4.png") center 100%/100% no-repeat;
} */

.pre-footer li{
  float: left;
  list-style: none;
  margin-right: 80px;
  display: flex;
  align-items: center;
  padding: 11px 0;
}
.pre-footer li img{
  margin-right: 20px;
  height: 50px;
  /* background-color:#C6CCB0 ;
  border: 2px solid #424B20; */
  padding: 7px;
  /* border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px; */
}
/* prefooter section*/
/*********************************** end prefooter section ****************************/



/******************************* Footer ************************************/
/* Footer */

.footer{
  /* background-image: url('images/footer/footer-bg.png'); */
  background: url('images/footer/footer-bg.png') center 100%/100% 100% no-repeat;
  color: white;
}

.footer-container{
  display: flex;
  justify-content: space-evenly;  
  flex-wrap: wrap;
  align-items: flex-end;
  margin: -1px;
}
.footer-col{
  margin-left: 80px;
}
.footer-container .footer-title-section{
margin-bottom: 15px;
}

.footer-container li{
  list-style: none;
  margin-bottom: 5px;
}
.footer-container a{
  color: white;
  font-weight: 200;
  text-decoration: none;
  font-size: 18px;
}
.footer-container a:hover{
  color: white;
  text-decoration: underline;
}

.footer-social input{
  height: 30.1px;
  padding: 0 5px;
  width: 130px;
}

.footer-usefullink img{
  margin-bottom: 28px;
}

.button-newsletter{
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin-left: -3px;
  background-color: #DEE355;
  border: none;
  position:absolute;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
/* copyright footer */
.footer-copyright{
  background-color: #424B1E;
  text-align: center;
  padding: 10px;
  color:#a6ad82;
  font-weight: 200;
}
/* copyright footer */

/* Footer */
/********************************* end Footer **********************************/



/* Large devices (large desktops, 1200px and up) */
/* @media all and (min-width: 1280px)  {

} */

/* Large devices (large desktops, 1200px and up) */
@media all and (min-width: 1024px) and (max-width: 1280px) {
  .myContainer{
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  /* .header-bottom{
    background: url(images/header/slider-bg.png) no-repeat center 0%/100% 100%;
    height: 700px;
  } */
  .header-product-img img {
    height: 365px;
    width: 500px;
    margin: 65px 50px;
}



/* header infos */



/* 
.header-product-infos button{
  text-decoration: none;
  color:#485032;
  padding: 8px 22px;
  font-weight: 600;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
} */
/* header infos */
.header-menu {
  padding: 0px 80px;
}
 }



/* medium devices (normal laptop ) */
@media all and (min-width: 768px) and (max-width: 1024px) {

  .myContainer{
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .header-product-img img {
    height: 286px;
    width: 507px;
    margin: 12px -73px;
}
  /* .header-bottom{
    background: url(images/header/slider-bg.png) no-repeat center 0%/100% 100%;
    height: 380px;
  } */
  .clientSay-card {
    color: white;
    display: block;
    width: 398px;
    margin-left: 45px;
    font-size: 11px;
   }

   .pre-clientSay-conatiner{
    height: 520px;
    background-size: cover;
   }

   .clientSay-conatiner{
     padding-top: 3%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
   }


/* header infos */






/* header infos */
.header-menu {
  padding: 0px 80px;
}
.hamburger {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #727E1E;
  font-size: 1.6rem;
  cursor: pointer;
}
.header-middle {
  justify-content: space-around;
}
.header-middle ul{list-style: none;display: none}
.nav-list.active {
  right: 0;
  width: 100%;
  top: 100%;
  height: 17rem;
  font-size: 17px;
  padding: 0;
  align-items: center;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #DEE355;
  transition: 0.9s;
  -webkit-transition: 0.9s;
  -moz-transition: 0.9s;
  -ms-transition: 0.9s;
  -o-transition: 0.9s;
}
 }




/* Medium devices (desktops, 992px and up) */
@media all and (min-width: 480px) and (max-width: 768px) {
  .myContainer{
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .hamburger {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #727E1E;
    font-size: 1.6rem;
    cursor: pointer;
  }
  .header-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 10px;
    flex-wrap: wrap;
    background-color:#424B1D ;
    color: white;
  }
  .header-top .pub-header{
    font-size: 12px;
  }
  .header-top h6{
    margin: 0;
    padding: 10px;
  }

  /* .header-bottom{
    background: url(images/header/slider-bg.png) no-repeat center 0%/100% 100%;
    height: 245px;
  } */
  .clientSay-card {
    color: white;
    display: block;
    width: 398px;
    margin-left: 45px;
    font-size: 12px;
   }
   .pre-clientSay-conatiner{
    height: 630px;
    background-size: cover;
   }
   .clientSay-conatiner {
    padding-top: 12%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
}
.header-product-img img {
  height: 189px;
  width: 65px;
  margin: -42px 0px;
}

/* .header-menu{display: none;} */

.header-product-img img {
  height: 189px;
  width: 65px;
  margin: -77px -5px;
}

.header-middle{
  justify-content: center
}
.header-middle ul{list-style: none;display: none}

.nav-list.active {
  right: 0;
  width: 100%;
  top: 100%;
  height: 17rem;
  font-size: 17px;
  padding: 0;
  align-items: center;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #DEE355;
  transition: 0.9s;
  -webkit-transition: 0.9s;
  -moz-transition: 0.9s;
  -ms-transition: 0.9s;
  -o-transition: 0.9s;
}
/* header infos */





/* .header-product-infos button{
  color:#485032;
  padding: 5px 8px;
  font-weight: 600;
  font-size: 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
} */
/* header infos */
.footer{
  background-image: url('images/footer/footer-bg.png');
  background-size: cover;
  color: white;
  display: flex;
  justify-content: center;
 }

 .pre-header-bottom {
  padding: 6rem;
}
.pre-clientSay-icon-right{
  margin-top: 4em !important;
}

}




/* Small devices (tablets, 768px and up) */
@media all and (max-width: 480px) { 
  .pre-clientSay-icon-right{
    margin-top: 4em !important;
  }
  .myContainer{
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  /* .header-menu{display: none;} */
  .header-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    flex-wrap: wrap;
    background-color:#424B1D ;
    color: white;
  }
  /* .header-bottom{
    background: url(images/header/slider-bg.png) no-repeat center 0%/100% 100%;
    height: 200px;
  } */
  .pre-clientSay-conatiner{
    height: 710px;
    background-size: cover;
  }
  .clientSay-conatiner {
    padding-top: 34%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    align-content: flex-start;
}
.clientSay-card {
  color: white;
  display: block;
  width: 398px;
  margin-left: 45px;
  font-size: 12px;
 }

.header-product-img img {
  height: 217px;
  width: 80px;
  margin: -119px 0px;
}
.footer-col{
  margin-left: 32px;
}
.footer-container{
  margin: 155px;
}
.footer{
  background-image: url('images/footer/footer-bg.png');
  background-size: cover;
  color: white;
  display: flex;
  justify-content: center;
 }
/* header infos */





/* .header-product-infos button{
  color:#485032;
  padding: 3px 8px;
  font-weight: 600;
  font-size: 8px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
} */
/* header infos */

.pre-header-bottom {
  padding: 6rem;
}

.nav-list.active {
  right: 0;
  width: 100%;
  top: 100%;
  height: 17rem;
  font-size: 17px;
  padding: 0;
  align-items: center;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #DEE355;
  transition: 0.9s;
  -webkit-transition: 0.9s;
  -moz-transition: 0.9s;
  -ms-transition: 0.9s;
  -o-transition: 0.9s;
}

}


/* extra Small devices (Phones, 360 px and up) */
@media all and (min-width: 320px) and (max-width: 480px)  { 

  .hamburger {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #727E1E;
    font-size: 1.6rem;
    cursor: pointer;
}

  .nav-list.active {
    right: 0;
    width: 100%;
    top: 100%;
    height: 17rem;
    font-size: 17px;
    padding: 0;
    align-items: center;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #DEE355;
    transition: 0.9s;
    -webkit-transition: 0.9s;
    -moz-transition: 0.9s;
    -ms-transition: 0.9s;
    -o-transition: 0.9s;
  }
  
.header-middle{
  justify-content: flex-end;
}
.header-middle ul{list-style: none;display: none}

.header-menu {
  padding: 0px 80px;
}

  .pre-clientSay-icon-right{
    margin-top: 4em !important;
  }
  .myContainer{
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  /* .header-bottom{
    background: url(images/header/slider-bg.png) no-repeat center 0%/100% 100%;
    height: 147px;
  } */
  /* header infos */




/* .header-product-infos button{
  color:#485032;
  padding: 3px 5px;
  font-weight: 600;
  font-size: 8px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
} */
/* header infos */

.pre-header-bottom {
  padding: 6rem;
}
}